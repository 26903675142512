/* Hide the increment and decrement buttons for number input */
.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
  @apply appearance-none m-0;
}

.custom-number-input {
  @apply appearance-none;
  -moz-appearance: textfield; /* Hides the buttons in Firefox */
}
